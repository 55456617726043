$(document).ready(function () {
    const $html = $('html');
    const $body = $('body');
    const $header = $('[data-plugin="sticky"]');
    const $h_height = $('#top').innerHeight();
    const $page_header = $('.header-full-image')
    const $is_fixed = $header.hasClass('header-over-image');
    const $top_nav = $('#top-nav');

    //Hamburger
    let $button_close = $('[data-bs-toggle="collapse"].hamburger');

    if ($button_close.length > 0) {
        $button_close.on('click', function () {
            let $this = $(this);

            if (!$this.hasClass('is-active')) {
                $this.addClass('is-active');

                if ($(window).width() < 992) {
                    $top_nav.toggleClass('close');
                    $body.addClass('menu-open');
                    $html.addClass('menu-open');
                }
            } else {
                $this.removeClass('is-active');
                $body.removeClass('menu-open');
                $html.removeClass('menu-open');
                $top_nav.toggleClass('close');
            }
        });
    }

    //Sticky on mouse scroll
    if ($header.length > 0) {
        console.log($h_height);
        let position = $body.offset().top + $h_height;

        if (window.scrollY >= position) {
            $header.addClass('sticky');
            $html.addClass('sticky');

            if (!$is_fixed) {
                $body.css('margin-top', $h_height);
            }
        }

        $(window).scroll(function () {
            if ($(document).scrollTop() > position) {
                $header.addClass('sticky');
                $html.addClass('sticky');

                if ($page_header.length > 0 && $is_fixed) {
                    $page_header.css('margin-top', 0)
                } else {
                    $body.css('margin-top', $h_height);
                }
            } else {
                $header.removeClass('sticky');
                $html.removeClass('sticky');

                if ($page_header.length > 0 && $is_fixed) {
                    $page_header.css('margin-top', -$h_height)
                } else {
                    $body.css('margin-top', 0);
                }
            }
        });
    }

    if ($page_header.length > 0 && $is_fixed) {
        $header.addClass('add-contrast-links')
        $page_header.css('margin-top', -$h_height)
    }

    $('.navbar-nav .menu-item.menu-item-has-children.dropdown .dropdown-menu .menu-item-has-children.dropdown > .dropdown-toggle').on('click', function(e){
        e.stopPropagation();
    });
});
